import React, { useState } from "react";
import { Button } from "@chakra-ui/button";
import { Box, Heading, Collapse } from "@chakra-ui/react";
import ControlElement from "./ControlElement";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";

interface FileSelectionProps {
  filelist: string[];
  addFile: (filename: string) => void;
}

// Updated grouping function to handle "base" files correctly
const groupFileList = (filelist: string[]): Record<string, string[]> => {
  const groupedFiles: Record<string, string[]> = {};
  for (const file of filelist) {
    // Extract the group name and file extension properly
    const [prefix, extension] = file.split('.');
    const [groupName, ...rest] = prefix.split('_');

    // Check if the file is a "base" file without a separate name after "_"
    const fileName = rest.length === 0 ? "base" : rest.join('_');

    // Initialize the group if not already present
    if (!groupedFiles[groupName]) {
      groupedFiles[groupName] = [];
    }

    // Add the file name to the appropriate group
    groupedFiles[groupName].push(fileName);
  }

  return groupedFiles;
};

// The main FileSelection component
const FileSelection: React.FC<FileSelectionProps> = ({ filelist, addFile }) => {
  // State to manage the collapse/expand status of each group
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});
  const groupedFiles = groupFileList(filelist);

  // Toggle a specific group's expanded/collapsed state
  const toggleGroup = (group: string) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  return (
    <ControlElement title="Dateiauswahl">
      {Object.entries(groupedFiles).map(([group, files]) => (
        <Box key={group} mb="4">
          <Heading
            size="sm"
            onClick={() => toggleGroup(group)}
            cursor="pointer"
            mb="2"
          >
            {group} {expandedGroups[group] ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </Heading>
          <Collapse in={expandedGroups[group]} animateOpacity>
            {files.map((file) => {
              // Restore the full filename including extension
              const fullFilename = file === "base" ? `${group}.txt` : `${group}_${file}.txt`;
              return (
                <Button
                  key={file}
                  size="xs"
                  m="1"
                  onClick={() => addFile(fullFilename)}
                >
                  {file}
                </Button>
              );
            })}
          </Collapse>
        </Box>
      ))}
    </ControlElement>
  );
};

export default FileSelection;
